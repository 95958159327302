<template>
  <div>
    <div v-show="!loading" class="text-center">
      <h3>Create payment method</h3>
      <div class="p-4" id="stripe-elements-payment"></div>
    </div>
    <div v-if="!loading" class="text-right">
      <btn :disabled="!canSubmit" @click="onAddPaymentMethod" class="dark">Save payment method</btn>
    </div>
    <div v-if="loading" class="loader flex items-center justify-center mt-4 pt-4">
      <spinner class="info mr-4" :loading="1" size="2em" />
      <p>Loading..</p>
    </div>
  </div>
</template>

<script>
import StripePaymentsMixin from '../../../payments/stripe/StripePaymentsMixin'
import BillingMixin from '../../BillingMixin'

export default {
  name: 'NewPaymentMethod',
  props: {
    subscription: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  mixins: [StripePaymentsMixin, BillingMixin],
  emits: ['reload'],
  data() {
    return {
      loading: false,
      canSubmit: false,
      paymentIntent: null
    }
  },
  methods: {
    /**
     * Has the elements been set as valid
     */
    onElementsCompleted() {
      this.canSubmit = true
    },
    /**
     * We need a payment intent for its secret to use elements
     */
    async paymentIntentCreated(paymentIntent) {
      this.paymentIntent = paymentIntent
      try {
        await this.setPaymentMethodAsDefault(paymentIntent.payment_method)
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not set payment method as default',
          error: true
        })
      }
      this.$emit('reload', 'new')
      this.loading = false
    },
    /**
     * Handle adding a payment method
     */
    onAddPaymentMethod() {
      this.loading = true
      this.confirmPaymentElements()
    }
  },
  async mounted() {
    this.loading = true
    try {
      this.mapBillingDetails()
      // create payment intent
      const isNewSubscription = this.subscription && Object.keys(this.subscription).length > 0
      const { client_secret: clientSecret } = await this.createPaymentIntent({
        subscription: isNewSubscription ? this.subscription : null
      })
      if (!clientSecret) {
        this.loading = false
        return
      }
      this.clientSecret = clientSecret
      // create payment intent
      await this.createElements(true)
      // use payment element so we can offer other methods in the future
      await this.createPaymentElement()
    } catch (e) {
      this.$store.dispatch('alert', {
        message: e.message || 'Could not setup payment elements.',
        error: true
      })
    }
    this.loading = false
  }
}
</script>
