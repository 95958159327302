<template>
  <div class="col-12 col-sm-6 mt-4 mt-sm-0">
    <Card class="bg-white" :flat="true">
      <div class="flat-card-content h-100 w-100 flex flex-column">
        <div v-if="!loading" class="flex justify-between h-100 flex-column">
          <span class="flex flex-column" v-if="Object.keys(upcoming).length > 0">
            <p class="text-muted header">Next payment</p>
            <span class="title">{{ $f.currency(upcoming.amount_due / 100) }}</span>
            <span class="subtitle">on {{ $f.datetime(upcoming.period_end) }}</span>
          </span>
          <span class="mt-4" v-if="Object.keys(upcoming).length > 0">
            <a @click="goToBillingSupport" class="text-dark">
              <u>Request cancellation</u>
            </a>
          </span>
          <p class="text-muted" v-if="Object.keys(upcoming).length === 0">No upcoming payment</p>
        </div>
        <div v-if="loading" class="loader flex items-center justify-center">
          <spinner class="info mr-4" :loading="1" size="2em" />
          <p></p>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import BillingMixin from '../BillingMixin'

export default {
  name: 'NextPayment',
  props: {
    upcoming: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async goToBillingSupport() {
      const link = await this.$store.dispatch('Keyvalue/get', {
        key: 'billing_link',
        global: true
      })
      c.openLink(link)
    }
  },
  mixins: [BillingMixin],
  data() {
    return {}
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  font-size: 3em;
}
.subtitle {
  margin-top: 0 !important;
  font-weight: 300 !important;
  font-size: 1.3em;
}
.header {
  font-size: 1.1em !important;
}
.flat-card-content {
  min-height: 240px;
  > div {
    flex: 1;
  }
}
</style>
