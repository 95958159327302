<template>
  <div class="col-12 col-sm-6">
    <Card :flat="true">
      <div class="flat-card-content h-100 w-100 flex flex-column">
        <div v-if="!loading" class="flex justify-between h-100 flex-column">
          <span class="flex flex-column" v-if="hasSubscription">
            <p class="text-muted header">Current subscription</p>
            <span class="title">
              <img
                v-if="currentSubscription.plan.product.metadata.icon === 'ribbon'"
                src="../../../assets/subscriptions/ribbon.svg"
              />
              <img
                v-if="currentSubscription.plan.product.metadata.icon === 'trophy'"
                src="../../../assets/subscriptions/trophy.svg"
              />
              <img
                v-if="currentSubscription.plan.product.metadata.icon === 'medal'"
                src="../../../assets/subscriptions/medal.svg"
              />
              <img
                v-if="currentSubscription.plan.product.metadata.icon === 'crown'"
                src="../../../assets/subscriptions/crown.svg"
              />
              {{ currentSubscription.plan.product.name }}
            </span>
            <span class="subtitle">{{ $f.currency(currentSubscription.plan.amount / 100) }}</span>
          </span>
          <p class="text-muted" v-if="!hasSubscription">No subscription set</p>
          <span>
            <a v-if="hasSubscription" class="dark" @click="onViewPlans"><u>Change Package</u></a>
            <a v-else class="dark" @click="onChoosePackage"><u>Choose Package</u></a>
          </span>
        </div>
        <div v-if="loading" class="loader flex items-center justify-center">
          <spinner class="info mr-4" :loading="1" size="2em" />
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  name: 'CurrentSubscription',
  data() {
    return {}
  },
  props: {
    currentSubscription: {
      type: Object,
      required: true,
      defaut: {}
    },
    onViewPlans: {
      type: Function
    },
    onChoosePackage: {
      type: Function
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasSubscription() {
      return Object.keys(this.currentSubscription).length > 0
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  font-size: 3em;
}
.subtitle {
  margin-top: 0 !important;
  font-weight: 300 !important;
  font-size: 1.3em;
}
.header {
  font-size: 1.1em !important;
}
.flat-card-content {
  min-height: 240px;
  > div {
    flex: 1;
  }
}
</style>
