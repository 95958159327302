<template>
  <div v-if="!loading" class="w-100 payment-history">
    <div
      v-if="response && response.data && response.data.length > 0 && !loading"
      class="list-group-item transaction-header bg-transparent border-0 flex justify-between w-100 items-center"
    >
      <span class="text-sm text-bold"> <u>Invoice</u> </span>
      <span class="text-sm text-bold text-center hidden md:block"> <u>Paid</u> </span>
      <span class="text-sm text-bold text-center"> <u>Amount</u> </span>
      <span class="text-sm text-bold text-center hidden md:block"> <u>Date</u> </span>
      <span class="text-sm text-bold text-center"> <u>Status</u> </span>
    </div>
    <div class="tab-section" v-if="response && response.data && !loading">
      <div class="transaction-ctn mb-2 p-3 rounded" v-for="item in response.data" :key="item.id">
        <PaymentHistoryItem :transaction="item" />
      </div>
    </div>
    <div v-if="response && loading" class="loader flex items-center justify-center">
      <spinner class="info mr-4" :loading="1" size="2em" />
      <p>Loading..</p>
    </div>
    <p
      class="text-muted p-2 text-center"
      v-if="response && response.data && response.data.length === 0"
    >
      No results
    </p>

    <div class="mt-3 flex justify-end">
      <Btn
        class="sm bg-white mr-2"
        :disabled="!response || page === 1"
        @click="() => loadMore(type, 'previous', status)"
      >
        Previous
      </Btn>
      <Btn
        class="sm bg-white"
        :disabled="!response || !response.has_more"
        @click="() => loadMore(type, 'next', status)"
      >
        Next
      </Btn>
    </div>
  </div>
  <div v-else class="w-100 flex justify-center">
    <spinner class="info transaction-list-loader" :loading="1" size="2em" />
  </div>
</template>

<script>
import BillingMixin from '../../BillingMixin'
import PaymentHistoryItem from './PaymentHistoryItem.vue'

export default {
  name: 'PaymentHistory',
  components: {
    PaymentHistoryItem
  },
  data() {
    return {
      response: null,
      loading: false,
      page: 1
    }
  },
  computed: {
    subscriptionId() {
      return (
        this.$store.state.session.company.stripe_customer_id &&
        this.$store.state.session.company.stripe_customer_id.includes('cus_')
      )
    }
  },
  async created() {
    if (!this.subscriptionId) return
    this.loading = true
    this.response = await this.getSubscriptionPayments({
      query: 'total>0'
    })
    this.loading = false
  },
  methods: {
    loadMore() {
      // TODO add pagination load more, for now we set the limit to 50 invoices
    }
  },
  mixins: [BillingMixin]
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.payment-history {
  min-height: 200px;
}
.transaction-header {
  > span {
    flex: 1;
  }
}
</style>
