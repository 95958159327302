<template>
  <Page title="Subscription management">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="row mb-4">
          <CurrentSubscription
            :loading="loading"
            :onViewPlans="onViewPlans"
            :onChoosePackage="onChoosePackage"
            :currentSubscription="currentSubscription"
          />
          <NextPayment :loading="loading" :upcoming="upcoming" />
        </div>
        <Card :flat="true">
          <p class="text-muted header">Payment History</p>
          <PaymentHistory />
        </Card>
      </div>
      <div class="col-12 col-md-4 mt-4 mt-md-0">
        <PaymentMethod @updated="onUpdated" :defaultMethodId="defaultMethodId" />
        <SubscriptionProgress
          class="mt-4"
          v-if="usage && !loading"
          :subscription="currentSubscription"
          :usage="usage"
        />
      </div>
    </div>

    <MiniModal
      ref="subscriptionModal"
      :click-away="false"
      :full="true"
      :closeable="true"
      :scrollable="true"
      :size="'full'"
    >
      <SubscriptionSelector @updated="onUpdated" />
    </MiniModal>

    <MiniModal
      ref="signupModal"
      :click-away="false"
      :full="true"
      :closeable="true"
      :scrollable="true"
      :size="'full'"
    >
      <SignUpForPackage @updated="onUpdated" />
    </MiniModal>
  </Page>
</template>

<script>
import Page from '../ui/Page.vue'
import SubscriptionSelector from '../billing/subscriptions/SubscriptionSelector.vue'
import BillingMixin from '../billing/BillingMixin'
import CurrentSubscription from '../billing/account/CurrentSubscription.vue'
import NextPayment from '../billing/account/NextPayment.vue'
import PaymentHistory from '../billing/account/paymentHistory/PaymentHistory.vue'
import PaymentMethod from '../billing/account/paymentMethods/PaymentMethod.vue'
import SubscriptionProgress from '../billing/account/SubscriptionProgress.vue'
import SignUpForPackage from '../billing/subscriptions/SignupForPackage.vue'

export default {
  name: 'Billing',
  components: {
    CurrentSubscription,
    NextPayment,
    PaymentHistory,
    Page,
    PaymentMethod,
    SubscriptionProgress,
    SubscriptionSelector,
    SignUpForPackage
  },
  data() {
    return {
      loading: false,
      currentSubscription: {},
      usage: null,
      customer: null,
      upcoming: {}
    }
  },
  mixins: [BillingMixin],
  created() {
    this.init()
  },
  computed: {
    defaultMethodId() {
      return this.customer && this.customer.invoice_settings
        ? this.customer.invoice_settings.default_payment_method
        : null
    }
  },
  methods: {
    onViewPlans() {
      this.$refs.subscriptionModal.open()
    },
    onChoosePackage() {
      this.$refs.signupModal.open()
    },
    onUpdated() {
      this.init()
    },
    setCurrent(current) {
      if (!current || ('value' in current && current.value === null)) return {}
      return current
    },
    async init() {
      this.loading = true
      this.customer = await this.getCustomer()
      const currentSubscription = await this.getCurrentSubscription()
      this.currentSubscription = this.setCurrent(currentSubscription)
      this.usage = await this.getSubscriptionUsage()
      this.upcoming = await this.getUpcomingInvoice()
      this.loading = false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header {
  font-size: 1.1em !important;
}
</style>
