<template>
  <div class="transaction-item flex justify-between w-100 items-center">
    <span>
      <a v-if="transaction.invoice_pdf" :href="transaction.invoice_pdf">Download</a>
    </span>
    <span class="text-center hidden md:block"
      >{{ $f.currency(transaction.amount_due / 100) }}
      {{ $f.uppercase(transaction.currency) }}</span
    >
    <span class="text-center"
      >{{ $f.currency(transaction.amount_paid / 100) }}
      {{ $f.uppercase(transaction.currency) }}</span
    >
    <span class="hidden md:flex flex-column items-center">
      {{ $f.datetime(transaction.created) }}
    </span>
    <span class="text-center">
      <span class="badge">{{ transaction.status }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'PaymentHistoryItem',
  props: {
    transaction: {
      type: Object
    }
  },
  data() {
    return {}
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.transaction-item {
  > span {
    flex: 1 !important;
  }
}
</style>
