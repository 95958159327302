<template>
  <Subscriptions
    :plans="subscriptions"
    :selectText="selectText"
    @updated="(data) => $emit('updated', data)"
    :loading="loading"
  />
</template>

<script>
import BillingMixin from '../BillingMixin'
import Subscriptions from '../subscriptions/Subscriptions.vue'

export default {
  name: 'SubscriptionSelector',
  emits: ['updated'],
  data() {
    return {
      loading: false,
      subscriptions: null
    }
  },
  async mounted() {
    this.loading = true
    this.subscriptions = await this.getSuggestedSubscriptions()
    this.loading = false
  },
  methods: {},
  components: {
    Subscriptions
  },
  mixins: [BillingMixin],
  props: {
    selectText: {
      type: String,
      default: 'Upgrade'
    }
  }
}
</script>
