<template>
  <div>
    <h3 class="text-center mb-4">Payment methods</h3>
    <div>
      <CardList class="mb-4">
        <CardListItem v-for="method in paymentMethods" :key="method.id">
          <div class="flex justify-between w-100">
            <div class="payment-method--type flex items-center">
              <font-awesome-icon v-if="method.type === 'card'" class="text-xl" icon="credit-card" />

              <font-awesome-icon
                v-if="method.type === 'bank_account'"
                class="text-xl"
                icon="university"
              />

              <div
                v-if="method.type === 'card'"
                class="flex flex-column ml-4 justify-center payment-method--card"
              >
                <span class="mb-1">**** **** {{ method.card.last4 }}</span>
                <small> expires {{ method.card.exp_month }}/{{ method.card.exp_year }} </small>
              </div>

              <span v-if="method.type === 'bank_account'" class="flex">
                <small>{{ method.routing_number || method.acss_debit.routing_number }}</small>
                <small class="ml-3"> **** {{ method.last4 || method.acss_debit.last4 }} </small>
              </span>

              <span v-if="method.id === defaultMethodId" class="badge bg-success ml-2 text-white"
                >Default</span
              >
            </div>
            <div>
              <Btn
                @click="() => onDelete(method)"
                v-tooltip="'Remove payment method'"
                v-if="paymentMethods.length > 1"
                class="bg-white"
              >
                <font-awesome-icon icon="trash" />
              </Btn>
              <Btn
                @click="() => onDefault(method)"
                v-if="method.id !== defaultMethodId"
                v-tooltip="'Set method as default'"
                class="bg-white"
              >
                <font-awesome-icon icon="square-check" />
              </Btn>
            </div>
          </div>
        </CardListItem>
      </CardList>
    </div>
  </div>
</template>

<script>
import BillingMixin from '../../BillingMixin'

export default {
  name: 'PaymentMethods',
  mixins: [BillingMixin],
  props: {
    paymentMethods: {
      type: Array
    },
    defaultMethodId: {
      type: String
    }
  },
  emits: ['reload', 'setDefault'],
  data() {
    return {}
  },
  methods: {
    async onDelete(method) {
      try {
        await this.removePaymentMethod(method.id)
        this.$emit('reload', 'updated')
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not remove payment method',
          error: true
        })
      }
    },
    async onDefault(method) {
      try {
        await this.setPaymentMethodAsDefault(method.id)
        this.$emit('reload', 'updated')
        this.$emit('setDefault', method.id)
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not set payment method as default',
          error: true
        })
      }
    }
  }
}
</script>
