<template>
  <card :flat="true">
    <div class="flat-card-content" v-if="usage && limits">
      <p class="text-muted header pb-3">Subscription progress</p>
      <div class="flex w-100 mb-3">
        <font-awesome-icon icon="user" class="progress-icon" />
        <div class="progress-content">
          <span>User count ({{ usage.users || 0 }}/{{ limits.users || 0 }})</span>
          <div class="progress wizard-progress">
            <div
              class="progress-bar bg-info"
              role="progressbar"
              :style="`width: ${userPercentage}%`"
            ></div>
          </div>
          <small>
            Current plan includes {{ limits.users }} users and you have activated {{ usage.users }}.
          </small>
        </div>
      </div>
      <div class="flex w-100">
        <font-awesome-icon icon="house" class="progress-icon" />
        <div class="progress-content">
          <span>Project count ({{ usage.projects || 0 }}/{{ limits.projects || 0 }})</span>
          <div class="progress wizard-progress">
            <div
              class="progress-bar bg-info"
              role="progressbar"
              :style="`width: ${projectPercentage}%`"
            ></div>
          </div>
          <small>
            Current plan includes {{ limits.projects }} booked projects and you have booked
            {{ usage.projects }}.
          </small>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import BillingMixin from '../BillingMixin'

export default {
  name: 'SubscriptionProgress',
  data() {
    return {
      limits: {
        users: 0,
        projects: 0,
        onboarding: 0
      }
    }
  },
  mixins: [BillingMixin],
  computed: {
    /**
     * Percentage of user count vs limit
     */
    userPercentage() {
      if (!this.usage || !this.limits) return 0
      return ((this.usage.users || 0) / (this.limits.users || 0)) * 100
    },
    /**
     * Percentage of project count vs limit
     */
    projectPercentage() {
      if (!this.usage || !this.limits) return 0
      return ((this.usage.projects || 0) / (this.limits.projects || 0)) * 100
    },
    /**
     * Percentage of onboarding count vs limit
     */
    onboardingPercentage() {
      if (!this.usage || !this.limits) return 0
      return ((this.usage.onboarding || 0) / (this.limits.onboarding || 0)) * 100
    }
  },
  mounted() {
    if (this.subscription) {
      // parse the limits from subscription or product
      const { limits } = this.getSubscriptionLimits(this.subscription)
      this.limits = limits
    }
  },
  props: {
    subscription: {
      type: Object
    },
    usage: {
      type: Object
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.wizard-progress {
  width: 100%;
  height: 10px;
  margin: 0.5em 0;
}
.progress-icon {
  font-size: 2.5em;
  margin-right: 15px;
  margin-top: 8px;
}
.progress-content {
  flex: 1;
}
.header {
  font-size: 1.1em !important;
}
.flat-card-content {
  min-height: 236px;
}
</style>
