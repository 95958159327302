<template>
  <Page :title="title">
    <slot></slot>
  </Page>
</template>

<script>
export default {
  name: 'PageOld',
  props: {
    title: {
      type: String
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.page {
  overflow-y: auto;
  padding-bottom: 4em !important;
}
</style>
