<template>
  <div>
    <card :flat="true">
      <div class="flat-card-content">
        <div class="flex flex-column h-100 justify-between" v-if="!loading && result">
          <div>
            <p class="mb-4 text-muted header">
              Payment information
              <btn class="sm info edit-btn" v-if="result.data" @click="onEditPaymentMethod">
                <font-awesome-icon icon="edit" />
              </btn>
            </p>
            <div class="flex mt-3" v-if="display && display.type === 'card'">
              <img :src="paymentMethods.find((i) => i.value === 'card').icon" />
              <div class="flex flex-column ml-4">
                <p>{{ $f.uppercase(display.card.brand) }} {{ display.card.last4 }}</p>
                <span>Exp. date {{ display.card.exp_month }}/{{ display.card.exp_year }}</span>
              </div>
            </div>
            <p class="pt-3 text-muted" v-if="!display">No saved payment methods</p>
          </div>
          <a @click="managePaymentMethods" v-if="result.data" class="text-dark">
            <u>Manage payment methods</u>
          </a>
        </div>
        <div v-if="loading" class="w-100 flex justify-center">
          <spinner class="info transaction-list-loader" :loading="1" size="2em" />
        </div>
      </div>
    </card>

    <MiniModal
      ref="editPaymentMethodModal"
      :click-away="false"
      :closeable="true"
      :scrollable="true"
      :size="'md'"
    >
      <NewPaymentMethod @reload="onReload" />
    </MiniModal>

    <MiniModal
      ref="paymentMethodsModal"
      :click-away="false"
      :closeable="true"
      :scrollable="true"
      :size="'md'"
    >
      <PaymentMethods
        v-if="result && result.data"
        :defaultMethodId="defaultMethodId"
        :paymentMethods="result.data"
        @reload="onReload"
        @set-default="setDefault"
      />
    </MiniModal>
  </div>
</template>

<script>
import BillingMixin from '../../BillingMixin'
import NewPaymentMethod from './NewPaymentMethod.vue'
import { paymentMethods } from '../../../payments/paymentMethods/PaymentMethods'
import PaymentMethods from './PaymentMethods.vue'

export default {
  name: 'PaymentMethod',
  mixins: [BillingMixin],
  props: {
    defaultMethodId: {
      type: String
    }
  },
  emits: ['updated'],
  data() {
    return {
      result: null,
      loading: false,
      display: null,
      paymentMethods
    }
  },
  methods: {
    /**
     * When adding payment methods
     */
    onEditPaymentMethod() {
      this.$refs.editPaymentMethodModal.open()
    },
    /**
     * When viewing payment methods
     */
    managePaymentMethods() {
      this.$refs.paymentMethodsModal.open()
    },
    /**
     * After adding, deleting or setting default
     * reload the dashboard
     */
    onReload(action) {
      if (action === 'updated') this.$refs.paymentMethodsModal.close()
      if (action === 'new') this.$refs.editPaymentMethodModal.close()
      this.reload()
      this.$emit('updated')
    },
    /**
     * Reload the payment methods
     */
    async reload() {
      if (!this.subscriptionId) return
      this.loading = true
      try {
        // retrieve payment methods
        this.result = await this.getPaymentMethods()
        if (this.result.data && this.result.data.length > 0) {
          this.display = this.defaultMethodId
            ? this.result.data.find((i) => i.id === this.defaultMethodId)
            : this.result.data[0]
        }
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not retrieve payment elements.',
          error: true
        })
      }
      this.loading = false
    },
    /**
     * Handling setting payment method as default
     */
    setDefault(methodId) {
      this.display = this.result.data.find((i) => i.id === methodId)
    }
  },
  created() {
    // retrieve payment method info
    this.reload()
  },
  computed: {
    subscriptionId() {
      return (
        this.$store.state.session.company.stripe_customer_id &&
        this.$store.state.session.company.stripe_customer_id.includes('cus_')
      )
    }
  },
  components: {
    NewPaymentMethod,
    PaymentMethods
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.edit-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
.flat-card-content {
  min-height: 240px;
  display: flex;
  flex-direction: column;
  > div {
    flex: 1;
  }
}
.header {
  font-size: 1.1em !important;
}
</style>
